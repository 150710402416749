<template>
    <div>
        <div style="background-color: #ffffff;">
            <div class="pageCenter titleBar" :style="{width: pageWidth+'px'}">
                <div class="titleBar_left">
                    <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="@/assets/logo.png">
                    <div class="titleBar_left_txt">
                        企业管理中心
                    </div>
                </div>
                <div class="titleBar_right" @click="$oucy.replace('/')">
                    返回首页
                </div>
            </div>
        </div>
        <div class="pageCenter breadcrumbBar" :style="{width: pageWidth+'px'}">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <!-- <el-breadcrumb-item :to="{ path: '/ReleaseCenter' }">发布中心</el-breadcrumb-item> -->
                <el-breadcrumb-item :to="{ path: '/ReleaseCenter' }">买家发布</el-breadcrumb-item>
                <el-breadcrumb-item>支付预缴款</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button icon="el-icon-notebook-2" type="text" @click="$oucy.go('/AfficheList?type=1')">帮助手册</el-button>
        </div>
        <div class="container">
            <div class="pageCenter" :style="{width: pageWidth+'px'}">
                <el-container>
                    <div style="padding: 0 0 50px;width: 100%">
                        <h3>支付预缴款</h3>
                        <!-- 有货 -->
                        <div class="content">
                            <!-- <div class="text-left">订单信息</div> -->
                            <div class="contentCenter">
                                <div v-if="1">
                                    <div class="table">
                                        <div class="tableHeader m-t-25">
                                            <div class="cell-2">求购信息</div>
                                            <div class="cell-3">求购类型</div>
                                            <div class="cell-4">求购数量</div>
                                            <div class="cell-5">交货日期</div>
                                            <div class="cell-6">求购位置</div>
                                        </div>
                                        <div class="tableBody">
                                            <div v-for="(v,i) of [1,2]" :key="i">
                                       
                                                <div class="items m-t-10">
                                                    <div class="item p-10" v-for="(v,i) of [1,2]" :key="i">
                                                        <div class="cell-2">
                                                            <div class="merchandise">
                                                                <div class="merchandiseImg"><img src="@/assets/images/fur1.png"></div>
                                                                <div class="merchandiseText p-l-10">
                                                                    <div class="name c-1 14 line-2">布艺沙发现代简约小户型科技布北欧乳胶冬夏两用沙发客厅贵妃组合</div>
                                                                    <div class="specification c-2 m-t-15 f12 line-2">规格：套餐2 6人位 4+2 浅灰色带靠垫抱枕吉祥如意抱枕</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="f14 c-2 cell-3">家具求购</div>
                                                        <div class="f14 c-2 cell-4">
                                                            <span class="f12">1</span>
                                                        </div>
                                                        <div class="f14 c-2 cell-5">
                                                           2021-8-9
                                                        </div>
                                                        <div class="f14 c-2 cell-6">广东省佛山市禅城区石湾街道镇中路
佛山家博城C座6楼60001</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center " style="margin-top:86px">
                                <div>
                                    <div class="df df-ai-c df-jc-c">
                                        <div>
                                            <img src="@/assets/QRCode/purchase_code.png" class="payQRCode">
                                            <div class="f12 c-1">请使用微信扫码付款</div>
                                        </div>
                                        <div class="text-left m-l-30">
                                            <div class="c-1 f12">本次交易供需支付:</div>
                                            <div class="price f24 m-t-15">￥20200.00</div>
                                            <div class="c-1 f12 m-t-20">剩余支付时间 <span class="time">00:15:48</span>超时订单自动关闭</div>
                                        </div>
                                    </div>
                                    <div style="margin-top:86px">
                                        <el-button type="primary" @click="finishOrder">已完成支付</el-button>
                                        <el-button>返回</el-button>
                                    </div>
                                </div>
                                <div class="duringPayment"><img src="@/assets/images/stockCar/await.gif" class="awaitGif">扫码成功，正在支付...</div>
                            </div>
                        </div>
                    </div>
                </el-container>
            </div>
        </div>
    </div>
</template>
<script>
import pay from './pay.js'
export default pay
</script>
<style lang="less" scoped>
@import "./pay.less";
</style>